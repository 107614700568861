<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('aqvenginepowerratio.title')">
					<v-layout wrap>
						<v-flex xs12 md8>
							<pui-text-field
								:label="$t('aqvenginepowerratio.name')"
								v-model="model.name"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('aqvenginepowerratio.acronym')"
								v-model="model.acronym"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqversionenginepowerratioid"
								:label="this.$t('aqvenginepowerratio.versionenginepowerratio')"
								:placeholder="this.$t('aqvenginepowerratio.phversionenginepowerratio')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqversionenginepowerratio"
								:modelFormMapping="{ aqversionenginepowerratioid: 'versionenginepowerratio' }"
								:itemsToSelect="versionenginepowerratioItemsToSelect"
								itemValue="aqversionenginepowerratioid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-select
								attach="aqenginetypesid"
								:label="this.$t('aqvenginepowerratio.enginetype')"
								:placeholder="this.$t('aqvenginepowerratio.phenginetype')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="aqenginetypes"
								:modelFormMapping="{ aqenginetypesid: 'enginetype' }"
								:itemsToSelect="enginetypesItemsToSelect"
								itemValue="aqenginetypesid"
								itemText="name"
							>
							</pui-select>
						</v-flex>
						<v-flex xs12 md4>
							<pui-text-field
								:label="$t('aqvenginepowerratio.potprinpottotal')"
								v-model="model.potprinpottotal"
								required
								toplabel
								maxlength="5"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md12>
							<pui-text-area
								v-model="model.description"
								:label="$t('aqvenginepowerratio.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('aqvenginepowerratio.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
export default {
	name: 'aqvenginepowerratioform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'aqvenginepowerratio',
			model: 'aqvenginepowerratio'
		};
	},
	computed: {
		versionenginepowerratioItemsToSelect() {
			return [{ aqversionenginepowerratioid: this.model.aqversionenginepowerratioid }];
		},
		enginetypesItemsToSelect() {
			return [{ eaqnginetypesid: this.model.aqenginetypesid }];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	methods: {}
};
</script>
